<template>
  <DatePicker
    v-if="type === 'datePicker'"
    :id="id"
    :name="id"
    type="text"
    class="form-control"
    :class="{ 'is-invalid': !!errorMessage, 'form-control-alt': isAlt || alt, disabled }"
    :placeholder="filledPlaceholder"
    :value="value"
    :format="dateFormat || 'dd/mm/yyyy'"
    :min-view-mode="dateMinViewMode"
    @input="onInput"
    @error="onInputError"
  />
  <DateTimePicker
    v-else-if="type === 'dateTimePicker'"
    :id="id"
    :name="id"
    :class="{ 'is-invalid': !!errorMessage, 'form-control-alt': isAlt || alt }"
    :placeholder="filledPlaceholder"
    :disabled="disabled"
    :value="value"
    :config="config"
    :format="dateFormat || 'DD/MM/YYYY HH:mm'"
    :min-view-mode="dateMinViewMode"
    @input="onInput"
  />
  <DateRangePicker
    v-else-if="type === 'dateRangePicker'"
    :id="id"
    :name="id"
    :class="{ 'is-invalid': !!errorMessage, 'form-control-alt': isAlt || alt }"
    :placeholder="filledPlaceholder"
    :disabled="disabled"
    :value="value"
    :min-view-mode="dateMinViewMode"
    :custom-date-range="dateRange"
    :config="config"
    :format="dateFormat || (config?.time ? 'DD MMM YYYY HH:mm' : 'DD/MM/YYYY')"
    @input="onInput"
  />
  <Select2
    v-else-if="type === 'select2' || type === 'select'"
    :id="id"
    :name="id"
    type="text"
    class="form-control"
    :disabled="disabled"
    :class="{ 'form-control-alt': isAlt || alt }"
    :placeholder="filledPlaceholder"
    :value="value"
    :allow-search="type === 'select2' || config?.allowSearch"
    :allow-clear="config?.allowClear"
    :error="errorMessage"
    @input="onInput"
  >
    <template v-if="options.some(o => o.children)">
      <optgroup v-for="option in options" :key="option.title" :label="option.title">
        <option v-for="child in option.children" :key="child.value" :value="child.value" :icon="child.icon" :level="child.level">
          {{ child.label }}
        </option>
      </optgroup>
    </template>
    <template v-else>
      <option v-for="option in options" :key="option.value" :value="option.value" :icon="option.icon" :level="option.level">
        {{ option.label }}
      </option>
    </template>
  </Select2>
  <MultiSelectText
    v-else-if="type === 'select-array'"
    :id="id"
    :name="id"
    :disabled="disabled"
    :class="{ 'is-invalid': !!errorMessage, 'form-control-alt': isAlt || alt, disabled }"
    :placeholder="filledPlaceholder"
    :value="value"
    :unique="uniqueOnly"
    @input="onInput"
    @error="onInputError"
  />
  <MultiSelectOptions
    v-else-if="type === 'select-array-list'"
    :id="id"
    :name="id"
    :disabled="disabled"
    :class="{ 'is-invalid': !!errorMessage, 'form-control-alt': isAlt || alt, disabled }"
    :placeholder="filledPlaceholder"
    :value="value"
    :options="options"
    :overflow="overflow"
    :search="search"
    @input="onInput"
    @itemAdded="onItemAdded"
    @itemRemoved="onItemRemoved"
  />
  <MultiSearchSelectOptions
    v-else-if="type === 'select-array-search'"
    :id="id"
    :label="label"
    :name="id"
    :disabled="disabled"
    :class="{ 'is-invalid': !!errorMessage, 'form-control-alt': isAlt || alt, disabled }"
    :placeholder="filledPlaceholder"
    :value="value"
    :options="options"
    :overflow="overflow"
    :search="search"
    :action="action"
    @input="onInput"
    @itemAdded="onItemAdded"
    @itemRemoved="onItemRemoved"
  />
  <MultiCheckOptions
    v-else-if="type === 'multi-check-options'"
    :id="id"
    :name="id"
    :disabled="disabled"
    :class="{ 'is-invalid': !!errorMessage, 'form-control-alt': isAlt || alt, disabled }"
    :placeholder="filledPlaceholder"
    :value="value"
    :options="options"
    :overflow="overflow"
    :search="search"
    @input="onInput"
    @itemAdded="onItemAdded"
    @itemRemoved="onItemRemoved"
  />
  <input
    v-else-if="type === 'text'"
    :id="id"
    :name="id"
    :disabled="disabled"
    type="text"
    class="form-control"
    :class="{ 'is-invalid': !!errorMessage, 'form-control-alt': isAlt || alt, disabled }"
    :placeholder="filledPlaceholder"
    :value="value"
    @input="e => onInput(e.target.value)"
  />
  <input
    v-else-if="type === 'number'"
    :id="id"
    :name="id"
    type="number"
    class="form-control"
    :class="{ 'is-invalid': !!errorMessage, 'form-control-alt': isAlt || alt }"
    :placeholder="filledPlaceholder"
    :value="value"
    :min="min"
    :max="max"
    @input="e => onInput(e.target.value)"
  />
  <input
    v-else-if="type === 'password'"
    :id="id"
    :name="id"
    :disabled="disabled"
    type="password"
    class="form-control"
    :class="{ 'is-invalid': !!errorMessage, 'form-control-alt': isAlt || alt, disabled }"
    :placeholder="filledPlaceholder"
    :value="value"
    @input="e => onInput(e.target.value)"
  />
  <textarea
    v-else-if="type === 'textarea'"
    :id="id"
    :name="id"
    :disabled="disabled"
    type="text"
    rows="4"
    class="form-control"
    :class="{ 'is-invalid': !!errorMessage, 'form-control-alt': isAlt || alt, disabled }"
    :placeholder="filledPlaceholder"
    :value="value"
    @input="e => onInput(e.target.value)"
  />
  <select
    v-else-if="type === 'select-old'"
    :id="id"
    v-model="itemValue"
    class="form-control"
    :class="{ 'is-invalid': !!errorMessage, 'form-control-alt': isAlt || alt, disabled }"
    :name="id"
    :disabled="disabled"
    :placeholder="filledPlaceholder"
    :value="value"
    @input="e => onInput(e.target.value)"
  >
    <option v-if="filledPlaceholder && !options.find(o => o.value === '' || o.value === null)" value="" disabled selected hidden>
      {{ filledPlaceholder }}
    </option>
    <option v-for="option in options" :key="option.value" :value="option.value" :disabled="option.disabled">{{ option.label }}</option>
  </select>
  <select
    v-else-if="type === 'select-multiple'"
    :id="id"
    class="form-control"
    :class="{ 'is-invalid': !!errorMessage, 'form-control-alt': isAlt || alt, disabled }"
    :name="id"
    :disabled="disabled"
    :placeholder="filledPlaceholder"
    multiple="true"
    @input="
      e =>
        onInput(
          Array(e.target.selectedOptions.length)
            .fill()
            .map((u, i) => e.target.selectedOptions[i].value)
        )
    "
  >
    <option v-for="option in options" :key="option.value" :value="option.value">{{ option.label }}</option>
  </select>

  <div v-else-if="type === 'radio'" :class="{ 'is-invalid': !!error, 'form-control-alt': isAlt || alt }">
    <div v-for="option in options" :key="`${id}-${option.value}`" class="custom-control custom-radio custom-control-inline custom-control-primary">
      <input
        :id="`${id}-${option.value}`"
        v-model="itemValue"
        type="radio"
        class="custom-control-input"
        :name="`${id}-${option.value}`"
        :value="`${option.value}`"
        :disabled="disabled"
        @input="e => onInput(e.target.value)"
      />
      <label class="custom-control-label" :for="`${id}-${option.value}`">{{ option.label }}</label>
    </div>
  </div>
  <div v-else-if="type === 'file'">
    <input :id="id" type="file" :name="id" :multiple="multiple" />
  </div>
  <RangeSlider v-else-if="type === 'slider'" :id="id" :min="min" :max="max" :value="value" :type="sliderType" postfix="%" @onSlide="onInput" />
</template>
<script>
import DatePicker from './DatePicker';
import DateTimePicker from './DateTimePicker';
import DateRangePicker from './DateRangePicker';
import MultiSelectText from './MultiSelectText';
import MultiSelectOptions from './MultiSelectOptions';
import MultiSearchSelectOptions from './MultiSearchSelectOptions';
import MultiCheckOptions from './MultiCheckOptions';
import RangeSlider from './RangeSlider';
import Select2 from './Select2';

export default {
  name: 'FormItem',
  components: {
    DatePicker,
    DateTimePicker,
    DateRangePicker,
    MultiSelectText,
    RangeSlider,
    Select2,
    MultiSearchSelectOptions,
    MultiSelectOptions,
    MultiCheckOptions
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: 'text'
    },
    placeholder: {
      type: String,
      default: null
    },
    value: {
      type: null,
      default: ''
    },
    options: {
      type: [Array, Object],
      default: () => []
    },
    error: {
      type: String,
      default: ''
    },
    isAlt: {
      type: Boolean,
      default: false
    },
    alt: {
      type: Boolean,
      default: false
    },
    dateFormat: {
      type: String,
      default: null
    },
    dateMinViewMode: {
      type: String,
      default: 'days'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    search: {
      type: Boolean,
      default: false
    },
    overflow: {
      type: Boolean,
      default: false
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 100
    },
    sliderType: {
      type: String,
      default: 'single'
    },
    hideNoOptionsLabel: {
      type: Boolean,
      default: false
    },
    uniqueOnly: {
      type: Boolean,
      default: false
    },
    dateRange: {
      type: Object,
      default: () => {}
    },
    action: {
      type: Function,
      default: () => {}
    },
    config: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      itemValue: '',
      childError: ''
    };
  },
  computed: {
    filledPlaceholder() {
      if (!this.placeholder) {
        return `Enter a ${this.label}...`;
      } else {
        return this.placeholder;
      }
    },
    filledSelectPlaceholder() {
      if (!this.placeholder) {
        return `Select a ${this.label}...`;
      } else {
        return this.placeholder;
      }
    },
    errorMessage() {
      return this.error || this.childError;
    }
  },
  watch: {
    value(val) {
      this.itemValue = val;
    }
  },
  created() {
    this.itemValue = this.value;
  },
  methods: {
    onInput(val) {
      let value = val;

      if (val === 'true') {
        value = true;
      } else if (val === 'false') {
        value = false;
      } else if (val === 'null') {
        value = null;
      }

      this.$emit('input', value);
    },
    onItemAdded(val) {
      this.$emit('itemAdded', val);
    },
    onItemRemoved(val) {
      this.$emit('itemRemoved', val);
    },
    onInputError(val) {
      this.childError = val;
      this.$emit('error', val);
    }
  }
};
</script>
