<template>
  <select ref="input" v-bind="$attrs" class="js-select2" :data-placeholder="placeholder" :value="value">
    <option v-if="allowClear"></option>
    <slot></slot>
  </select>
</template>
<script>
export default {
  name: 'Select2',
  props: {
    value: {
      type: [String, Boolean, Number, null],
      default: ''
    },
    error: {
      type: String,
      default: null
    },
    id: {
      type: String,
      required: true
    },
    allowSearch: {
      type: Boolean,
      default: false
    },
    allowClear: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: 'Select...'
    }
  },
  data() {
    return {
      settings: {
        allowClear: false,
        dropdownAutoWidth: true,
        width: 'resolve'
      }
    };
  },
  watch: {
    error: function (val) {
      if (val) {
        this.showError();
      } else {
        jQuery(this.$refs.input).removeClass('is-invalid');
      }
    },
    value: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        jQuery(this.$refs.input).val(newVal);
        jQuery(this.$refs.input).select2({ value: newVal, placeholder: this.placeholder, ...this.settings });
      }
    }
  },
  beforeMount() {
    if (!this.allowSearch) {
      this.settings.minimumResultsForSearch = Infinity;
    }

    if (this.allowClear) {
      this.settings.allowClear = this.allowClear;
    }
  },
  mounted() {
    this.settings.templateResult = item => {
      const level = item.element?.attributes?.level?.value;
      if (!level) return item.text;

      const indentPx = (level || 0) * 15;
      const $result = jQuery('<span></span>');

      $result.css('padding-left', indentPx + 'px');

      if (indentPx === 0) {
        $result.css('font-weight', 600);
      }

      $result.text(item.text);

      return $result;
    };

    jQuery(this.$refs.input).select2({ placeholder: this.placeholder, ...this.settings });

    jQuery(this.$refs.input).on('change', e => {
      this.$emit('input', e.target.value);
      this.$emit('change', e);
    });

    if (this.error) {
      this.showError();
    } else {
      jQuery(this.$refs.input).css('border-color', '');
    }
  },
  beforeDestroy() {
    jQuery(this.$refs.input).select2('destroy');
  },
  methods: {
    showError() {
      jQuery(this.$refs.input).addClass('is-invalid');
      jQuery(`#${this.id}`).css('display', 'block');
    }
  }
};
</script>
<style>
.select2-container--default .select2-selection--single .select2-selection__clear {
  margin-right: 5px;
  font-size: 20px;
  color: dimgrey;
}
</style>
